export default function Input({ type = "text", className, ...props }) {
  return (
    <input
      className={`
        p-4 rounded-lg border border-ultramarine-200 bg-white outline-none leading-none
        ${className}
      `}
      type={type}
      {...props}
    />
  );
}
