import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export default function Header() {
  const { edit } = useContext(AppContext);
  return (
    <header
      className={`pb-4 flex justify-center items-center gap-2 border-b ${
        edit && "hidden"
      }`}
    >
      <div>
        <img
          className="max-w-[32px]"
          src="images/LogoMark_256x256.png"
          alt="Paul Barahona Logo"
        />
      </div>
      <span className="text-2xl font-black tracking-[.5rem]">OLAROID</span>
    </header>
  );
}
