import { useContext } from "react";
import { BiColorFill, BiFont, BiFontColor, BiSun } from "react-icons/bi";
import { MdOutlinePhotoSizeSelectLarge } from "react-icons/md";
import { AppContext } from "../../context/AppContext";
import Button from "../Button/Button";
import ButtonIcon from "../Button/ButtonIcon";

const options = [
  { name: "background", icon: <BiColorFill /> },
  { name: "color", icon: <BiFontColor /> },
  { name: "font", icon: <BiFont /> },
  { name: "filter", icon: <BiSun /> },
  { name: "size", icon: <MdOutlinePhotoSizeSelectLarge /> }
];

export default function ConfigurationMenu() {
  const { activeSection, setActiveSection } = useContext(AppContext);
  return (
    <menu className="max-w-full pt-4 flex items-center justify-center gap-2 bg-white sticky bottom-0">
      {options.map(({ name, icon }) => (
        <Button
          variant={activeSection === name ? "black" : "white"}
          onClick={() => setActiveSection(name)}
        >
          <ButtonIcon>{icon}</ButtonIcon>
        </Button>
      ))}
    </menu>
  );
}
