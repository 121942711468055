import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Option from "../Option/Option";
import ConfigurationContainer from "./ConfigurationContainer";
import ConfigurationTitle from "./ConfigurationTitle";

export default function ConfigurationBackground() {
  const { activeSection, background, setBg } = useContext(AppContext);

  const [value, setValue] = useState("");

  const handleChange = ({ target }) => {
    const { value } = target;
    setValue(value.startsWith("#") ? value : "#" + value);
  };

  useEffect(() => {
    setBg(value || "white");
  }, [setBg, value]);

  return (
    <div className={activeSection !== "background" && "hidden"}>
      <ConfigurationTitle>Color de Fondo</ConfigurationTitle>
      <ConfigurationContainer>
        <Option checked={background === "black"}>
          <Button
            className="min-w-[128px]"
            variant="black"
            onClick={() => setBg("black")}
          >
            Negro
          </Button>
        </Option>
        <Option checked={background === "white"}>
          <Button
            className="min-w-[128px]"
            variant="white"
            onClick={() => setBg("white")}
          >
            Blanco
          </Button>
        </Option>
        <Option checked={background !== "white" && background !== "black"}>
          <Input
            className="min-w-[128px]"
            placeholder="#FFFFFF"
            value={value}
            onChange={handleChange}
          />
        </Option>
      </ConfigurationContainer>
    </div>
  );
}
