import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Option from "../Option/Option";
import OptionTitle from "../Option/OptionTitle";
import ConfigurationContainer from "./ConfigurationContainer";
import ConfigurationTitle from "./ConfigurationTitle";

const fonts = [
  "Caveat",
  "Rubik 80s Fade",
  "Sevillana",
  "Passions Conflict",
  "Inspiration",
  "Comfortaa",
  "Pacifico",
  "Indie Flower",
  "Amatic SC"
];

export default function ConfigurationFont() {
  const { activeSection, font: contextFont, setFont } = useContext(AppContext);

  return (
    <div className={activeSection !== "font" && "hidden"}>
      <ConfigurationTitle>Familia de Fuente</ConfigurationTitle>
      <ConfigurationContainer>
        {fonts.map((font) => (
          <Option checked={font === contextFont} onClick={() => setFont(font)}>
            <OptionTitle className={`font-['${font.replaceAll(" ", "_")}']`}>
              {font}
            </OptionTitle>
          </Option>
        ))}
      </ConfigurationContainer>
    </div>
  );
}
