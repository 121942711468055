import React, { useCallback, useState } from "react";
import useBoolean from "../hooks/useBoolean";

const AppContext = React.createContext();

export function AppProvider({ children }) {
  const [edit, setEdit] = useBoolean();
  const [activeSection, setActiveSection] = useState("background");
  const [options, setOptions] = useState({
    background: "white",
    color: "black",
    size: "md",
    font: "Caveat"
  });
  const [filters, setFilters] = useState({
    blur: 0,
    brightness: 100,
    contrast: 100,
    grayscale: 0,
    hueRotate: 0,
    invert: 0,
    saturate: 100,
    sepia: 0
  });
  const [src, setSrc] = useState("images/placeholder.png");
  const [title, setTitle] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [image, setImage] = useState(null);

  const setBg = useCallback((background = "white") => {
    setIsReady(false);
    setOptions((prevOptions) => ({ ...prevOptions, background }));
  }, []);

  const setColor = useCallback((color = "black") => {
    setIsReady(false);
    setOptions((prevOptions) => ({ ...prevOptions, color }));
  }, []);

  const setSize = useCallback((size = "md") => {
    setIsReady(false);
    setOptions((prevOptions) => ({ ...prevOptions, size }));
  }, []);

  const setFont = useCallback((font = "Caveat") => {
    setIsReady(false);
    setOptions((prevOptions) => ({ ...prevOptions, font }));
  }, []);

  const handleFilters = useCallback(({ name, value }) => {
    setIsReady(false);
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  }, []);

  const value = {
    edit,
    activeSection,
    ...options,
    filters,
    src,
    title,
    isReady,
    image,
    setBg,
    setColor,
    setSize,
    setFont,
    setEdit,
    setActiveSection,
    handleFilters,
    setSrc,
    setTitle,
    setIsReady,
    setImage
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export { AppContext };
