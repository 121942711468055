export default function Option({ children, checked, ...props }) {
  return (
    <label
      className={`
      grid gap-4 p-4 border-2 border-ultramarine-200 rounded-lg cursor-pointer hover:border-ultramarine-400
      ${checked && "border-ultramarine-500"}
    `}
      {...props}
    >
      {children}
    </label>
  );
}
