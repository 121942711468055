import { useState } from "react";

export default function useBoolean(defaultState = false) {
  const [state, setState] = useState(defaultState);

  const handleState = {
    toggle() {
      setState((prevState) => !prevState);
    },
    on() {
      setState(true);
    },
    off() {
      setState(false);
    }
  };

  return [state, handleState];
}
