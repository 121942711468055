import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Button from "../Button/Button";
import Option from "../Option/Option";
import ConfigurationContainer from "./ConfigurationContainer";
import ConfigurationTitle from "./ConfigurationTitle";

const sizes = [
  { size: "sm", name: "Pequeño" },
  { size: "md", name: "Mediano" },
  { size: "lg", name: "Grande" }
];

export default function ConfigurationSize() {
  const { activeSection, size: contextSize, setSize } = useContext(AppContext);

  return (
    <div className={activeSection !== "size" && "hidden"}>
      <ConfigurationTitle>Tamaño de Descarga</ConfigurationTitle>
      <ConfigurationContainer>
        {sizes.map(({ size, name }) => (
          <Option checked={size === contextSize}>
            <Button
              className="min-w-[128px]"
              variant="white"
              onClick={() => setSize(size)}
            >
              {name}
            </Button>
          </Option>
        ))}
      </ConfigurationContainer>
    </div>
  );
}
