import Button from "../Button/Button";
import ButtonIcon from "../Button/ButtonIcon";
import ButtonText from "../Button/ButtonText";

import { IoImageOutline, IoClose } from "react-icons/io5";
import Input from "../Input/Input";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import ConfigurationBackground from "../Configuration/ConfigurationBackground";
import ConfigurationColor from "../Configuration/ConfigurationColor";
import ConfigurationSize from "../Configuration/ConfigurationSize";
import ConfigurationMenu from "../Configuration/ConfigurationMenu";
import ConfigurationFont from "../Configuration/ConfigurationFont";
import ConfigurationFilter from "../Configuration/ConfigurationFilter";

export default function Configuration() {
  const { edit, setEdit, setSrc, setTitle, setIsReady } = useContext(
    AppContext
  );
  const [value, setValue] = useState("");

  useEffect(() => {
    setTitle(value);
    setIsReady(false);
  }, [value, setTitle, setIsReady]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleImage = (e) => {
    try {
      const [image] = e.target.files;
      const blobURL = URL.createObjectURL(image);
      setSrc(blobURL);
      setIsReady(false);
    } catch (error) {
      alert(error.message || error.toString());
    }
  };

  return (
    <section className="max-h-[50vh] grid content-end gap-4 py-4 overflow-y-auto">
      <div className={`grid gap-2 ${edit && "hidden"}`}>
        <label className="grid">
          <input
            type="file"
            className="hidden"
            onChange={handleImage}
            accept="image/all"
          />
          <Button className="pointer-events-none">
            <ButtonIcon>
              <IoImageOutline />
            </ButtonIcon>
            <ButtonText>Elegir Imagen</ButtonText>
          </Button>
        </label>
        <Input
          placeholder="Titulo"
          className="text-center"
          value={value}
          onChange={handleChange}
        />
      </div>

      <article className={`${edit || "hidden"} overflow-x-hidden`}>
        <header className="flex justify-end">
          <Button variant="black" className="w-auto" onClick={setEdit.off}>
            <IoClose />
          </Button>
        </header>

        <ConfigurationBackground />
        <ConfigurationColor />
        <ConfigurationSize />
        <ConfigurationFont />
        <ConfigurationFilter />
        <ConfigurationMenu />
      </article>
    </section>
  );
}
