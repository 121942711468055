const colorSchemas = {
  ultramarine: {
    color: "text-white",
    bg: "bg-ultramarine-500",
    hoverBg: "bg-ultramarine-600",
    shadow: "shadow-ultramarine-500/30"
  },
  paradise: {
    color: "text-white",
    bg: "bg-paradise-500",
    hoverBg: "bg-paradise-600",
    shadow: "shadow-paradise-500/30"
  },
  bright: {
    color: "text-slate",
    bg: "bg-bright-500",
    hoverBg: "bg-bright-600",
    shadow: "shadow-bright-500/30"
  },
  white: {
    color: "text-slate",
    bg: "bg-white",
    hoverBg: "bg-slate-100",
    shadow: "shadow-slate-100/30"
  },
  black: {
    color: "text-white",
    bg: "bg-slate-800",
    hoverBg: "bg-slate-900",
    shadow: "shadow-slate-500/30"
  }
};

const getColorSchema = (variant) => {
  const { color, bg, hoverBg, shadow } = colorSchemas[variant];
  return `${bg} ${color} hover:${hoverBg} hover:shadow-xl hover:${shadow}`;
};

export default function Button({
  className,
  children,
  variant = "ultramarine",
  ...props
}) {
  const colorSchema = getColorSchema(variant);

  return (
    <button
      className={`p-4 flex justify-center items-center gap-2 rounded-lg disabled:bg-slate-600 disabled:shadow-none ${colorSchema} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}
