export default function CardTitle({
  children,
  color = "black",
  font = "Caveat"
}) {
  return (
    <figcaption
      className={`
      text-center text-[${color}]
      font-['${font.replaceAll(" ", "_")}']
      `}
    >
      {children}
    </figcaption>
  );
}
