export default function Card({
  children,
  size = "xs",
  bg = "white",
  ...props
}) {
  const options = {
    xs: { spacing: "1rem", font: "1rem", width: "256px" },
    sm: { spacing: "2rem", font: "2rem", width: "512px" },
    md: { spacing: "4rem", font: "5rem", width: "1024px" },
    lg: { spacing: "8rem", font: "7.5rem", width: "2048px" }
  };

  const { spacing, font, width } = options[size];

  return (
    <figure
      className={`
        w-[${width}] max-w-[${width}]
        grid gap-[${spacing}] p-[${spacing}] 
        text-[${font}] bg-[${bg}]
        shadow shadow-xl shadow-ultramarine-500/20
      `}
      {...props}
    >
      {children}
    </figure>
  );
}
