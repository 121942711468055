const fonts = {
  Caveat:
    "https://cdn.jsdelivr.net/npm/@fontsource/caveat@4.5.9/files/caveat-all-400-normal.woff",
  "Rubik 80s Fade":
    "https://cdn.jsdelivr.net/npm/@fontsource/rubik-80s-fade@4.5.0/files/rubik-80s-fade-all-400-normal.woff",
  Sevillana:
    "https://cdn.jsdelivr.net/npm/@fontsource/sevillana@4.5.8/files/sevillana-all-400-normal.woff",
  "Passions Conflict":
    "https://cdn.jsdelivr.net/npm/@fontsource/passions-conflict@4.5.10/files/passions-conflict-all-400-normal.woff",
  Inspiration:
    "https://cdn.jsdelivr.net/npm/@fontsource/inspiration@4.5.10/files/inspiration-all-400-normal.woff",
  Comfortaa:
    "https://cdn.jsdelivr.net/npm/@fontsource/comfortaa@4.5.10/files/comfortaa-all-400-normal.woff",
  Pacifico:
    "https://cdn.jsdelivr.net/npm/@fontsource/pacifico@4.5.9/files/pacifico-all-400-normal.woff",
  "Abril FatFace": "fonts/abril-fatface-all-400-normal.woff",
  "Indie Flower":
    "https://cdn.jsdelivr.net/npm/@fontsource/indie-flower@4.5.10/files/indie-flower-all-400-normal.woff",
  "Amatic SC":
    "https://cdn.jsdelivr.net/npm/@fontsource/amatic-sc@4.5.9/files/amatic-sc-all-400-normal.woff"
};

export const getFontFaceRule = (fontFamily) => {
  return `@font-face {
    font-family: '${fontFamily}';
    src: url('${fonts[fontFamily]}');
  }`;
};
