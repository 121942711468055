import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Option from "../Option/Option";
import OptionSlider from "../Option/OptionSlider";
import OptionTitle from "../Option/OptionTitle";
import OptionHeader from "../Option/OptionHeader";
import ConfigurationContainer from "./ConfigurationContainer";
import ConfigurationTitle from "./ConfigurationTitle";

const filters = [
  { name: "blur", min: 0, max: 10, defaultValue: 0, title: "Desenfoque" },
  { name: "brightness", min: 0, max: 200, defaultValue: 100, title: "Brillo" },
  { name: "contrast", min: 0, max: 200, defaultValue: 100, title: "Contraste" },
  {
    name: "grayscale",
    min: 0,
    max: 100,
    defaultValue: 0,
    title: "Escala de Grises"
  },
  { name: "hueRotate", min: 0, max: 360, defaultValue: 0, title: "Tono" },
  { name: "invert", min: 0, max: 1, defaultValue: 0, title: "Invertido" },
  {
    name: "saturate",
    min: 0,
    max: 200,
    defaultValue: 100,
    title: "Saturación"
  },
  { name: "sepia", min: 0, max: 100, defaultValue: 0, title: "Sepia" }
];

const Control = ({ name, min, max, defaultValue, title }) => {
  const { filters, handleFilters } = useContext(AppContext);
  const [value, setValue] = useState(filters[name]);

  const handleChange = ({ target }) => {
    const { value } = target;
    setValue(value);
  };

  useEffect(() => {
    handleFilters({ name, value });
  }, [name, value, handleFilters]);

  return (
    <Option onDoubleClick={() => setValue(defaultValue)}>
      <OptionHeader>
        <OptionTitle>{title}</OptionTitle>
        <OptionTitle>{value}</OptionTitle>
      </OptionHeader>
      <OptionSlider min={min} max={max} value={value} onChange={handleChange} />
    </Option>
  );
};

export default function ConfigurationFilter() {
  const { activeSection } = useContext(AppContext);

  return (
    <div className={activeSection !== "filter" && "hidden"}>
      <ConfigurationTitle>Filtros</ConfigurationTitle>
      <ConfigurationContainer className="grid whitespace-normal overflow-hidden">
        {filters.map(Control)}
      </ConfigurationContainer>
    </div>
  );
}
