import Button from "../Button/Button";
import ButtonIcon from "../Button/ButtonIcon";
import ButtonText from "../Button/ButtonText";
import { BiEdit, BiDownload, BiScreenshot } from "react-icons/bi";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import domtoimage from "dom-to-image";
import imageCompression from "browser-image-compression";
import { getFontFaceRule } from "../../helpers/getFontFaceRule";

export default function Actions() {
  const {
    font,
    edit,
    setEdit,
    isReady,
    setIsReady,
    image,
    setImage
  } = useContext(AppContext);
  const [state, setState] = useState("Generar");

  const handleGenerate = async () => {
    try {
      setIsReady(false);
      const $style = document.querySelector("#style");
      $style.innerHTML = getFontFaceRule(font);
      const $card = document.querySelector("#card");
      setState("Generando...");
      const cardAsImage = await domtoimage.toBlob($card);
      setState("Comprimiendo...");
      const compressImage = await imageCompression(cardAsImage, {
        maxSizeMB: 2
      });
      setImage(compressImage);
      setState("Generar");
      setIsReady(true);
    } catch (error) {
      setIsReady(false);
      alert(error.message || error.toString());
    }
  };

  const handleDownload = () => {
    const a = document.createElement("A");
    a.href = URL.createObjectURL(image);
    a.download = "polaroid.png";
    a.click();
  };

  return (
    <section className={`grid gap-2 boder-t ${edit && "hidden"}`}>
      <Button variant="black" onClick={setEdit.on}>
        <ButtonIcon>
          <BiEdit />
        </ButtonIcon>
        <ButtonText>Editar</ButtonText>
      </Button>

      <div className="flex gap-2">
        <Button className="w-full" onClick={handleGenerate}>
          <ButtonIcon>
            <BiScreenshot />
          </ButtonIcon>
          <ButtonText>{state}</ButtonText>
        </Button>
        <Button
          className="w-full"
          variant="paradise"
          disabled={!isReady}
          onClick={handleDownload}
        >
          <ButtonIcon>
            <BiDownload />
          </ButtonIcon>
          <ButtonText>Descargar</ButtonText>
        </Button>
      </div>
    </section>
  );
}
