const blurMultiples = { xs: 1, sm: 2, md: 4, lg: 8 };

export default function CardImage({
  src = "images/placeholder.png",
  alt = "placeholder",
  blur = 0,
  brightness = 100,
  contrast = 100,
  grayscale = 0,
  hueRotate = 0,
  invert = 0,
  saturate = 100,
  sepia = 0,
  size = "xs"
}) {
  return (
    <div
      className={`
      w-full leading-0
      blur-[${blur * blurMultiples[size]}px]
      brightness-[${brightness}%]
      contrast-[${contrast}%]
      grayscale-[${grayscale}%]
      hue-rotate-[${hueRotate}deg]
      invert-[${invert}]
      saturate-[${saturate}%]
      sepia-[${sepia}%]
    `}
    >
      <img className="max-w-full w-full" src={src} alt={alt} />
    </div>
  );
}
