import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Card from "../Card/Card";
import CardImage from "../Card/CardImage";
import CardTitle from "../Card/CardTitle";

export default function Preview() {
  const { background, color, font, filters, src, title } = useContext(
    AppContext
  );

  return (
    <section className={`p-4 grid place-items-center overflow-y-auto`}>
      <Card bg={background}>
        <CardImage src={src} {...filters} />
        <CardTitle color={color} font={font}>
          {title}
        </CardTitle>
      </Card>
    </section>
  );
}
