import { useContext } from "react";
import Card from "./components/Card/Card";
import CardImage from "./components/Card/CardImage";
import CardTitle from "./components/Card/CardTitle";
import Actions from "./components/Layout/Actions";
import Configuration from "./components/Layout/Configuration";
import Header from "./components/Layout/Header";
import Preview from "./components/Layout/Preview";
import { AppContext } from "./context/AppContext";

export default function App() {
  const {
    edit,
    background,
    color,
    font,
    size,
    filters,
    src,
    title
  } = useContext(AppContext);

  return (
    <main className="overflow-hidden max-w-screen max-h-screen">
      <style id="style"></style>
      <Card id="card" size={size} bg={background}>
        <CardImage src={src} size={size} {...filters} />
        <CardTitle color={color} font={font}>
          {title}
        </CardTitle>
      </Card>
      <section
        className={`
        fixed inset-0 z-20 p-4 grid bg-white
        ${edit ? "grid-rows-[1fr_auto]" : "grid-rows-[auto_1fr_auto_auto]"}
        `}
      >
        <Header />
        <Preview />
        <Configuration />
        <Actions />
      </section>
    </main>
  );
}
